<template>
  <div class="flex flex-col bg-white">
    <PageHeader title="Customer Case Studies" sub_title="" img="/images/caseStudies_header.webp" />
    <div class="container mx-auto p-4">
      <div class="flex flex-col items-center justify-center flex-wrap my-8 border-2 rounded-md">
        <div class="w-full flex flex-col">
          <div class="text-2xl mx-4">Tags</div>
          <div class="w-3/4 flex flex-wrap items-start justify-start py-2">
            <div
              :key="key"
              v-for="(tag, key) in state.tags"
              v-show="!tag.selected"
              @click="actions.addFilter(key)"
              class="text-lg bg-blue-500 px-2 my-2 mx-2 text-white cursor-pointer rounded-full capitalize"
            >
              {{ key }}
              <span class="fa fa-plus"></span>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col">
          <div class="text-2xl mx-4">Active</div>
          <div class="w-3/4 flex flex-wrapitems-start justify-start py-2">
            <div
              :key="key"
              v-for="(tag, key) in state.tags"
              @click="actions.removeFilter(key)"
              v-show="tag.selected"
              class="min-w-72 text-center text-lg bg-red-500 px-2 mx-2 text-white cursor-pointer rounded-full capitalize"
            >
              {{ key }}
              <span class="fa fa-close"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mx-auto flex flex-wrap justify-center pb-16">
      <CaseStudyCard
        v-for="study in state.filteredStudies"
        :tags="study.tags"
        :key="study.path"
        :title="study.title"
        :img="study.img"
        :sub_title="study.sub_title"
        :summary="study.summary"
        :path="study.path"
      />
      <div
        v-if="Object.values(state.filteredStudies).length === 0"
        class="min-h-150 text-4xl flex flex-col items-center text-gray-800 pb-16"
      >
        <div>No Case Studies Using Current Selection</div>
        <div class="my-2"></div>
        <div>Please Select Additional Tag/s To Filter By</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader";
import CaseStudyCard from "@/components/CaseStudyCard";

export default {
  name: "CaseStudies",
  components: { CaseStudyCard, PageHeader },
  computed: {
    filtered: function() {
      const vm = this;
      const filtered = new Set();
      Object.entries(vm.state.tags).map(([key, value]) => {
        return Object.values(vm.state.text.caseStudies.studies).map(study => {
          if (value.selected && study.tags.includes(key)) {
            filtered.add(JSON.stringify(study));
          }
        });
      });
      return [...filtered].map(item => {
        if (typeof item === "string") return JSON.parse(item);
        else if (typeof item === "object") return item;
      });
    },
  },
};
</script>
