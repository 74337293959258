<template>
  <div class="flex flex-col bg-white">
    <div class="container mx-auto flex flex-col items-center justify-center py-16">
      <img src="/images/404.webp" alt="Page Not Found" />
      <router-link class="mx-2 text-2xl text-blue-500" to="/home">Return to Homepage</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>
